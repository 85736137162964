import React from 'react';
import { ReactAppOrParcel } from 'single-spa-react';

import { createReactParcel } from '../utils/createReactParcel';
import { SurveyBlock } from '../components/survey';

const initialParcel = createReactParcel({
  loadRootComponent: () => {
    const Component: React.FC = () => <SurveyBlock />;
    return Promise.resolve(Component);
  },
  domElementGetter: () => {
    const surveyContainer = document.createElement('div');
    surveyContainer.id = 'acc-survey';
    document.body.insertBefore(surveyContainer, document.querySelector('main'));

    return surveyContainer;
  },
});

export const survey: ReactAppOrParcel<any> = {
  ...initialParcel,
  unmount: props =>
    initialParcel.unmount(props).then(() => {
      const surveyContainer = document.querySelector('#acc-survey');

      surveyContainer?.parentNode?.removeChild(surveyContainer);
    }),
};
