import localforage from 'localforage';

import { ID_TOKEN_KEY, ACCESS_TOKEN_KEY } from '../enums';

export const clearAuthTokens = () => {
  localStorage.removeItem(ID_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);

  // NOTE: used by the Auth Worker
  return localforage.removeItem(ACCESS_TOKEN_KEY);
};
