import React from 'react';
import { Button } from '@dazn/acc-sdk-design-system';
import surveyData from '../../nps_files/survey.json';
import * as styles from './styles';

interface SurveyButtons {
  surveyState: {
    stage: number;
    score?: number;
    feedback?: string;
  };
  onNavigate: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: () => void;
}

export const SurveyButtons: React.FC<SurveyButtons> = ({ surveyState, onNavigate, onSubmit }) => {
  const surveyLength = surveyData.surveyLength;
  const { stage, score, feedback } = surveyState;

  const buttons: React.ReactNode[] = [];

  if (stage < surveyLength) {
    buttons.push(
      <Button
        key="nextBtn"
        type="button"
        aria-label={'survey-next-btn'}
        disabled={!score && score !== 0}
        onClick={() => onNavigate(Math.min(stage + 1, surveyLength))}
        css={styles.surveyBtn}
      >
        Next
      </Button>
    );
  } else {
    buttons.push(
      <Button
        key="submitBtn"
        type="submit"
        aria-label={'survey-submit-btn'}
        css={[styles.surveyBtn, styles.surveyBtnSolid]}
        disabled={(!score && score !== 0) || !feedback}
        onClick={() => onSubmit()}
      >
        Done
      </Button>
    );
  }

  if (stage > 1) {
    buttons.push(
      <Button
        key="prevBtn"
        type="button"
        aria-label={'survey-prev-btn'}
        onClick={() => onNavigate(Math.max(stage - 1, 1))}
        css={styles.surveyBtn}
      >
        Back
      </Button>
    );
  }

  return <div css={styles.surveyButtonContainer}>{buttons.map(button => button)}</div>;
};
