import React from 'react';
import { ReactAppOrParcel } from 'single-spa-react';

import { createReactParcel } from '../utils/createReactParcel';
import { Nav } from '../components/nav';
import { AppProps } from 'single-spa';

const initialParcel = createReactParcel({
  loadRootComponent: ({ links }: { links: any[] }) => {
    const Component: React.FC = () => <Nav links={links} />;
    return Promise.resolve(Component);
  },
  domElementGetter: () => {
    const navContainer = document.createElement('div');
    navContainer.id = 'acc-nav';
    document.body.insertBefore(navContainer, document.querySelector('main'));

    return navContainer;
  },
});

export const nav: ReactAppOrParcel<any> = {
  ...initialParcel,
  unmount: (props: AppProps) =>
    initialParcel.unmount(props).then(() => {
      const navContainer = document.querySelector('#acc-nav');

      navContainer?.parentNode?.removeChild(navContainer);
    }),
};
