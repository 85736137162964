import React, { useState } from 'react';
import { usePubSub } from '@dazn/acc-sdk-hooks';
import neuron from '@dazn/acc-sdk-neuron';

import NavigationPrompt from '../NavigationPrompt';
import { SerializedStyles } from '@emotion/react';

interface LinkProps {
  href: string;
  className?: string;
  /**
   * If `true`, will always show a confirmation dialog to the user whether or not
   * the current module has communicated to Portal that it should do so.
   * @default false
   */
  alwaysConfirmNavigation?: boolean;
  confirmNavigationTitle?: string;
  /**
   * Called after a successful navigation.
   */
  onNavigate?: VoidFunction;
  children: React.ReactNode;
  css?: SerializedStyles;
}

/**
 * Link component to perform SPA-style in-page navigation rather than traditional browser navigation.
 * Listens to `neuron.navigationPrompt`, so that modules can dynamically declare that they should not
 * be navigated away from via this Link component without user confirmation. **NOTE:** there is a
 * separate file in this repo that also listens to `neuron.navigationPrompt` in order to enforce
 * user confirmation at the _browser navigation_ level.
 */
export const Link: React.FC<LinkProps> = ({
  href = '/',
  alwaysConfirmNavigation = false,
  confirmNavigationTitle,
  onNavigate,
  className,
  children,
  css,
}) => {
  const [navPromptIsOpen, setNavPromptIsOpen] = useState(false);

  const [shouldBlockNavigation, setShouldBlockNavigation] = usePubSub(
    {
      publish: neuron.navigationPrompt.update,
      subscribe: neuron.navigationPrompt.observe,
    },
    false
  );

  const continueNavigation = () => {
    // Unblock navigation otherwise it will be blocked
    // for the rest of the browser session
    setShouldBlockNavigation(false);

    window.history.pushState({}, 'DAZN Application Control Center', href);

    onNavigate?.();
  };

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (window.location.pathname !== href) {
      if (shouldBlockNavigation || alwaysConfirmNavigation) {
        return setNavPromptIsOpen(true);
      }
      continueNavigation();
    }
  };

  return (
    <>
      <a className={className} data-testid={`acc-nav-link:${href}`} href={href} onClick={onClick}>
        {children}
      </a>

      <NavigationPrompt
        isOpen={navPromptIsOpen}
        onClose={() => setNavPromptIsOpen(false)}
        title={confirmNavigationTitle}
        onConfirm={() => {
          setNavPromptIsOpen(false);
          continueNavigation();
        }}
      />
    </>
  );
};
