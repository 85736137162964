import React, { useEffect } from 'react';
import { css } from '@emotion/react';

import { createReactParcel } from '../utils/createReactParcel';
import { buildLoginUrl, jwt, isLoggedIn } from '../utils/auth';
import { redirectUser } from '../utils/redirect';
import { letItSnow } from '../utils/letItSnow';
import { IS_IFRAME } from '../constants';
import { NONCE_KEY } from '../enums';
import { AccessDialog } from '../components/access-dialog';

export const backgroundStyle = (backgroundUrl: string) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 3;
  height: 100%;

  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${backgroundUrl}) no-repeat center center;
  background-size: cover;
`;

interface LoginComponentProps {
  url: string;
  nonce: string;
  cdnUrl: string;
}

export const LoginComponent: React.FC<LoginComponentProps> = ({ url, nonce, cdnUrl }) => {
  useEffect(() => {
    const payload = jwt();

    if (isLoggedIn(payload)) {
      return redirectUser();
    }

    if (new Date().getMonth() === 11) {
      letItSnow();
    }

    if (!IS_IFRAME) {
      localStorage.setItem(NONCE_KEY, nonce);
    }
  }, [nonce]);

  return (
    <div id="acc-login" css={backgroundStyle(`${cdnUrl}/images/f1-pitstop.jpg`)}>
      <AccessDialog url={url} buttonText="Sign in here" />
    </div>
  );
};

export const login = createReactParcel({
  loadRootComponent: ({ CDN_URL, AUTH0_CLIENT_ID, AUTH0_CONNECTION, env }) => {
    const { url, nonce } = buildLoginUrl({
      auth0ClientId: AUTH0_CLIENT_ID,
      env,
      auth0Connection: AUTH0_CONNECTION,
    });

    const Component: React.FC = () => <LoginComponent url={url} nonce={nonce} cdnUrl={CDN_URL} />;

    return Promise.resolve(Component);
  },
});
