import { jwtDecode } from 'jwt-decode';
import localforage from 'localforage';

import type { JWTPayload, Env } from '../../types';
import { ACC_GROUPS, NONCE_KEY, ID_TOKEN_KEY, ACCESS_TOKEN_KEY, URL_PATHS_FROM_ENV } from '../enums';

export const jwt = (key = ID_TOKEN_KEY): JWTPayload | null => {
  const token = localStorage.getItem(key);
  return (token && jwtDecode(token)) || null;
};

export const hasValidGroups = (groups: string[] = []) => ACC_GROUPS.some(group => groups.includes(group));

export const isLoggedIn = (payload: JWTPayload | null) =>
  Boolean(payload && payload.nonce === localStorage.getItem(NONCE_KEY) && payload.exp > Math.floor(Date.now() / 1000));

const randomString = (length: number) => {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/';

  const bytes = new Uint8Array(length);
  const random = window.crypto.getRandomValues(bytes);

  let result = '';
  random.forEach(c => {
    result += charset[c % charset.length];
  });
  return result;
};

const generateNonce = () => randomString(16);

interface LoginConfig {
  auth0ClientId: string;
  env: Env;
  prompt?: string;
  auth0Connection: string;
}

const loginQuery = ({ auth0ClientId, env, prompt, auth0Connection }: LoginConfig) => {
  const authFlow = new URLSearchParams(window.location.search).get('authFlow');

  return {
    /* eslint-disable camelcase */
    client_id: auth0ClientId,
    response_type: authFlow === 'code' ? authFlow : 'id_token token',
    redirect_uri: `${window.location.origin}/auth0`,
    /* eslint-enable camelcase */

    scope: 'openid profile email groups',
    connection: auth0Connection,
    nonce: generateNonce(),
    audience: `https://acc-api.${URL_PATHS_FROM_ENV[env]}.com`,
    ...(prompt ? { prompt } : {}),
  };
};

export const buildLoginUrl = ({ auth0ClientId, env, prompt, auth0Connection }: LoginConfig) => {
  const queryObj = loginQuery({ auth0ClientId, env, prompt, auth0Connection });
  return {
    url: `https://dazn.eu.auth0.com/authorize?${new URLSearchParams(queryObj)}`,
    nonce: queryObj.nonce,
  };
};

export async function setAuthTokens({ location: { hash } }: Window = window) {
  const [, idToken] = hash.match(/id_token=([^&]+)/i) ?? [];
  const [, accessToken] = hash.match(/access_token=([^&]+)/i) ?? [];

  if (!idToken || !accessToken) throw Error('Unable to parse hash');

  window.localStorage.setItem(ID_TOKEN_KEY, idToken);
  window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

  // NOTE: used by the Auth Worker
  await localforage.setItem(ACCESS_TOKEN_KEY, accessToken);
}
