import React from 'react';
import { icons } from '@dazn/acc-sdk-design-system';

import { ID_TOKEN_KEY } from '../../../../enums';
import { Link } from '../Link';
import * as styles from './styles';

const { Person, Exit } = icons;

interface User {
  picture?: string | null;
  name?: string | null;
  email?: string | null;
}

// NOTE: JWTs use base64Url, which uses different characters versus base64 and the two are not compatible
const base64UrlToBase64 = (base64url: string) => base64url.replace(/-/g, '+').replace(/_/g, '/');

const getUserInfo = (idToken: string) => {
  const [, body] = idToken.split('.');
  try {
    return JSON.parse(atob(base64UrlToBase64(body)));
  } catch (e) {
    return {};
  }
};

interface AccountOptionsProps {
  minimize: boolean;
}

const getUser = (idToken: string | null) =>
  typeof idToken === 'string' && idToken.length > 0 ? getUserInfo(idToken) : {};

export const AccountOptions: React.FC<AccountOptionsProps> = ({ minimize }) => {
  const user: User = getUser(localStorage.getItem(ID_TOKEN_KEY));

  if (!user.name && !user.email) return null;

  return (
    <div css={styles.base(minimize)}>
      <Link css={styles.myProfileLink} className="PII" href="/my-profile">
        {typeof user.picture === 'string' ? (
          <div css={styles.imageContainer(minimize)}>
            <img src={user.picture} alt="User avatar" data-testid="AccountOptions Avatar" />
          </div>
        ) : (
          <div css={styles.imageContainer(minimize)}>
            <Person />
          </div>
        )}

        <div css={styles.userInfo(minimize)}>
          <p>{user.name}</p>
          <p>{user.email}</p>
        </div>
      </Link>

      <Link
        css={styles.signOutLink(minimize)}
        href="/logout"
        alwaysConfirmNavigation
        confirmNavigationTitle="Are you sure you want to log out?"
      >
        <Exit />
      </Link>
    </div>
  );
};
