import type { JWTPayload, BaseEnvironmentConfig } from '../../types';
import { buildLoginUrl, setAuthTokens, jwt } from '../utils/auth';
import { redirectToLogin } from '../utils/redirect';
import { NONCE_KEY } from '../enums';

const ONE_SECOND = 1000 as const;
const ONE_MINUTE = 60 * ONE_SECOND;
const REAUTH_INTERVAL = 15 * ONE_MINUTE;

const getLoggedInAt = (payload: JWTPayload) => payload.iat * ONE_SECOND;

function getTimeSinceLastLogin(): number {
  const payload = jwt();
  if (!payload) {
    redirectToLogin();
    return 0;
  }
  return Date.now() - getLoggedInAt(payload);
}

const tabIsVisible = () => !document.hidden;

export default ({ AUTH0_CLIENT_ID: auth0ClientId, env, AUTH0_CONNECTION: auth0Connection }: BaseEnvironmentConfig) => {
  let timeoutId: NodeJS.Timeout;
  let iframe: HTMLIFrameElement;
  let onIframeLoad: VoidFunction;

  function cleanUpIframeAndTimeout() {
    clearTimeout(timeoutId);

    iframe?.removeEventListener('load', onIframeLoad);
    try {
      document.body.removeChild(iframe);
    } catch {
      // no-op
    }
  }

  function executeIframeLogin(): void {
    if (tabIsVisible()) {
      const { nonce, url } = buildLoginUrl({
        auth0ClientId,
        env,
        prompt: 'none',
        auth0Connection,
      });

      onIframeLoad = () => {
        localStorage.setItem(NONCE_KEY, nonce);
        // NOTE: not awaiting this because it's unnecessary and makes testing very difficult
        setAuthTokens(iframe.contentWindow ?? undefined);
        cleanUpIframeAndTimeout();
        startTimeout();
      };

      iframe = document.createElement('iframe');
      iframe.setAttribute('hidden', '');
      iframe.setAttribute('src', url);
      iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts');
      iframe.onload = onIframeLoad;

      document.body.appendChild(iframe);
    }
  }

  function startTimeout(timeout: number = REAUTH_INTERVAL) {
    timeoutId = setTimeout(executeIframeLogin, timeout);
  }

  function onVisibilityChange() {
    if (tabIsVisible() && getTimeSinceLastLogin() > REAUTH_INTERVAL) {
      cleanUpIframeAndTimeout();
      executeIframeLogin();
    }
  }

  return Promise.resolve({
    bootstrap: () => Promise.resolve(),

    mount: () =>
      Promise.resolve().then(() => {
        startTimeout(REAUTH_INTERVAL - getTimeSinceLastLogin());
        document.addEventListener('visibilitychange', onVisibilityChange);
      }),

    unmount: () =>
      Promise.resolve().then(() => {
        cleanUpIframeAndTimeout();
        document.removeEventListener('visibilitychange', onVisibilityChange);
      }),
  });
};
