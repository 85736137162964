import React from 'react';
import { Button } from '@dazn/acc-sdk-design-system';

import * as styles from './styles';

interface ErrorProps {
  backgroundUrl: string;
  children: React.ReactNode;
}

export const Error: React.FC<ErrorProps> = ({ children, backgroundUrl }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    window.history.pushState({}, 'DAZN Application Control Center', '/');
  };

  return (
    <div css={styles.errorContainer(backgroundUrl)}>
      <h1 css={styles.errorTitle}>Oops!</h1>
      <div css={styles.errorSubtext}>{children}</div>
      <Button
        as="a"
        onClick={handleClick}
        css={styles.errorCta}
        // @ts-expect-error (providing `as` changes the props)
        href="/"
      >
        Back to Homepage
      </Button>
    </div>
  );
};
