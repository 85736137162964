import React from 'react';
import { Button, HeadingAlpha } from '@dazn/acc-sdk-design-system';
import { DAZNIcon } from '../dazn-icon';

import * as styles from './styles';

interface AccessDialogProps {
  url: string;
  buttonText: string;
  children?: React.ReactNode;
}

export const AccessDialog: React.FC<AccessDialogProps> = ({ url, buttonText, children }) => (
  <div css={styles.container}>
    <DAZNIcon />
    <HeadingAlpha css={styles.title}>Application Control Centre</HeadingAlpha>
    {children}
    <Button
      css={styles.button}
      as="a"
      // @ts-expect-error (providing `as` changes the props)
      href={url}
    >
      {buttonText}
    </Button>
  </div>
);
