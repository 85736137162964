import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact, { SingleSpaReactOpts } from 'single-spa-react';
import { Notice } from '@dazn/acc-sdk-design-system';

import { getRootElement } from './getRootElement';

const ErrorMessage = <Notice.Error>Something went wrong!</Notice.Error>;

export const createReactParcel = ({
  loadRootComponent,
  domElementGetter = getRootElement,
}: Pick<SingleSpaReactOpts<any>, 'loadRootComponent' | 'domElementGetter'>) =>
  singleSpaReact({
    React,
    ReactDOM,
    loadRootComponent,
    domElementGetter,
    errorBoundary: () => ErrorMessage,
  });
