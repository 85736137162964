import { tokens } from '@dazn/acc-sdk-design-system';
import { css } from '@emotion/react';

export const errorContainer = (backgroundUrl: string) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 10vw;
  background-color: ${tokens.colors.mako};
  /* stylelint-disable-next-line declaration-block-no-shorthand-property-overrides */
  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${backgroundUrl}) no-repeat center center;
  background-size: cover;
  color: ${tokens.colors.chalk};
`;

export const errorTitle = css`
  font-size: ${tokens.typography.sizes.headingBravo};
  font-family: trim, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
`;

export const errorSubtext = css`
  font-size: ${tokens.typography.sizes.headingDelta};
  font-family: oscine, Helvetica, Arial, sans-serif;
`;

export const errorCta = css`
  font-family: ${tokens.fonts.trim};
  color: ${tokens.colors.tarmac};
  background-color: ${tokens.colors.neon};

  max-width: 300px;
  margin-top: 40px;
  padding: 8px 32px;

  font-weight: bold;
  font-size: ${tokens.typography.sizes.lead};
  line-height: 1.5;

  &:hover,
  &:focus {
    background-color: ${tokens.colors.yellowDark20};
  }
`;
